import { Pipe, PipeTransform } from '@angular/core';
import { ConditionEnum } from '@pulpo/pulpo-models';

@Pipe({
  name: 'PaymentConditionPipe',
  standalone: true,
  pure: true,
})
export class PaymentConditionPipe implements PipeTransform {
  transform(value?: ConditionEnum): string {
    switch (value) {
      case ConditionEnum.BEFORE:
        return 'Avant';
      case ConditionEnum.ON:
        return 'Le';
      case ConditionEnum.AFTER:
        return 'Après';
      default:
        return '';
    }
  }
}
