import { Pipe, PipeTransform } from '@angular/core';
import { EventTypeEnum } from '@pulpo/pulpo-models';

@Pipe({
  name: 'PaymentEventTypePipe',
  standalone: true,
  pure: true,
})
export class PaymentEventTypePipe implements PipeTransform {
  transform(value?: EventTypeEnum): string {
    switch (value) {
      case EventTypeEnum.DEPARTURE:
        return 'Départ';
      case EventTypeEnum.RETURN:
        return 'Retour';
      case EventTypeEnum.RESERVATION:
        return 'Réservation';
      default:
        return '';
    }
  }
}
