import { Inject, Injectable } from '@angular/core';
import { CompanyFlightSettings } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CompanyFlightSettingsService extends ApiService<CompanyFlightSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'flight/api/company-flight-settings', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:4300';
    }
  }

  getByCompanyId(companyId: number) {
    return this.http.get<CompanyFlightSettings>(
      `${this.url}/${this.endpoint}/company/${companyId}`,
      { observe: 'response' }
    );
  }
}
