import { AccommodationBooking } from './accommodation-booking.model';
import { RateChoiceEnum } from './enums/rate-choice.enum';
import { WHCacheChildrenPolicy } from './webhotelier/wh-cache-children-policy.model';
import { WHCacheRoomCapacity } from './webhotelier/wh-cache-room-capacity.model';

export class AccommodationWHBooking extends AccommodationBooking {
  minStay: number;
  webHotelierBookingRoomDTOList: {
    id?: string;
    propertyCode: string;
    propertyName: string;
    roomCode: string;
    roomCategory: string;
    originalPrice?: number;
    priceWithProductMargin?: number;
    priceWithContractMargin?: number;
    star: number;
    description: string;
    imageUrl: string;
    capacity: WHCacheRoomCapacity;
    summaryId?: string;
    contractId?: number;
    adults: number;
    children: number;
    infants: number;
    rateChoice: RateChoiceEnum;
    staySettingsDTO: {
      nightsMin: number;
      nightsMax: number;
      roomsMax: number;
    };
    childrenPolicy: WHCacheChildrenPolicy;
    rateInfos: {
      rateId: string;
      boardName: string;
    }[];
    commbinationDTOS: { adults: number; children: number; infants: number }[];
  }[];
}
