import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  AccommodationProviderSettingsService,
  CountryService,
} from '@pulpo/pulpo-api';
import { AccommodationProviderEnum, Country } from '@pulpo/pulpo-models';

@Component({
  selector: 'accommodation-filter',
  templateUrl: './accommodation-filter.component.html',
  styleUrls: ['./accommodation-filter.component.scss'],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    CommonModule,
  ],
  standalone: true,
})
export class AccommodationFilterComponent implements OnInit {
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  filterForm: FormGroup;
  sources: AccommodationProviderEnum[] = [];
  countries: Country[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private accommodationProviderSettingsService: AccommodationProviderSettingsService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.initFilterForm();
    this.accommodationProviderSettingsService
      .getByCompany()
      .subscribe((res) => {
        if (res.body) {
          this.sources = res.body.bookingProviderList || [];
        }
      });
    this.countryService.findAllWithCache().subscribe((response) => {
      this.countries = response.body || [];
    });
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      internalReference: [''],
      countryId: [''],
      cityName: [''],
      bookingProvider: [''],
      name: [''],
    });
  }

  onSelectionChange() {
    this.filterEvent.emit(this.filterForm.value);
  }

  onResetFilters(): void {
    this.filterForm.reset();
    this.filterEvent.emit(this.filterForm.value);
  }
}
