import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyTypeEnum } from '@pulpo/pulpo-models';

@Pipe({
  name: 'PaymentCurrencyTypePipe',
  standalone: true,
  pure: true,
})
export class PaymentCurrencyTypePipe implements PipeTransform {
  transform(value?: CurrencyTypeEnum): string {
    switch (value) {
      case CurrencyTypeEnum.PERCENTAGE:
        return '%';
      case CurrencyTypeEnum.EURO:
        return '€';
      default:
        return '';
    }
  }
}
