import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { PaymentTerm } from '@pulpo/pulpo-models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentTermService extends ApiService<PaymentTerm> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'product/payment');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8091';
    }
  }

  getByPaymentTermId(id: string): Observable<HttpResponse<PaymentTerm>> {
    return this.http.get<PaymentTerm>(
      `${this.url}/${this.endpoint}/ref/${id}`,
      {
        observe: 'response',
      }
    );
  }
}
