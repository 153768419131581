import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'pulpo-two-side-lists',
  templateUrl: './pulpo-two-side-lists.component.html',
  styleUrls: ['./pulpo-two-side-lists.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
  ],
})
export class PulpoTwoSideListsComponent {
  @Input() firstTitle: string;
  @Input() secondTitle: string;
  @Input() fieldToShow: string;
  @Input() firstList: any[];
  @Output() firstListChange = new EventEmitter<any[]>();
  @Input() secondList: any[];
  @Output() secondListChange = new EventEmitter<any[]>();

  @Output() secondListAdd = new EventEmitter<any>();
  @Output() secondListRemove = new EventEmitter<any>();

  @Output() secondSelectedChange = new EventEmitter<any>();

  firstSelected?: any;
  secondSelected?: any;

  add() {
    if (!this.firstSelected || !this.firstSelected[0]) return;
    this.secondList.push(this.firstSelected[0]);
    this.firstList = this.firstList.filter(
      (item) => item.id !== this.firstSelected[0].id
    );
    this.secondListAdd.emit(this.firstSelected[0]);
    this.firstSelected = undefined;
    this.firstListChange.emit(this.firstList);
    this.secondListChange.emit(this.secondList);
  }

  remove() {
    if (!this.secondSelected || !this.secondSelected[0]) return;
    this.firstList.push(this.secondSelected[0]);
    this.secondList = this.secondList.filter(
      (item) => item.id !== this.secondSelected[0].id
    );
    this.secondListRemove.emit(this.secondSelected[0]);
    this.secondSelected = undefined;
    this.firstListChange.emit(this.firstList);
    this.secondListChange.emit(this.secondList);
  }
}
